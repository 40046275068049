import store from '@/store';

export default {
  install(Vue) {
    store.call = function (identifier, payload) {
      if (identifier in this.getters) {
        return this.getters[identifier];
      } else if (identifier in this._actions) {
        return this.dispatch(identifier, payload);
      } else if (identifier in this._mutations) {
        this.commit(identifier, payload, { root: true });
      }

      return null;
    };

    store.get = function (identifier) {
      return this.getters[identifier];
    };

    store.action = function (identifier, payload) {
      return this.dispatch(identifier, payload);
    };

    store.mutate = function (identifier, payload) {
      this.commit(identifier, payload, { root: true });
    };

    this.load(Object.keys(store._actions).filter(key => key.includes("/")), this.loadAction);
    this.load(Object.keys(store.getters).filter(key => key.includes("/")), this.loadGetter);
  },
  load(entries, funct) {
    for (const key of entries) {
      const [namespace, varname] = key.split('/');

      if (!(namespace in store)) {
        store[namespace] = Object.create(null);
      }

      //store[namespace][varname] = 
      funct(store[namespace], varname, key);
    }
  },
  loadAction(target, varname, key) {
    target[varname] = function (payload) {
      return store.dispatch(key, payload)
    }
  },
  loadGetter(target, varname, key) {
    //target[varname] = store.getters[key];
    Object.defineProperty(target, varname, {
      get: () => store.getters[key]
    })
  }
};
