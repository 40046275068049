
<template>
  <v-text-field
    v-model="data"
    label="Icon"
    :append-icon="value"
    autocomplete="one-time-code"
    :disabled="disabled"
    :color="color || 'primary'"
    hint="Google 'MaterialDesignIcons.com'"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {},
};
</script>

<style scoped></style>
