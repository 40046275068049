<template>
  <popup ref="popup" exitable persistent max-width="600px" v-on="$listeners" @on-submit="submit" @on-clear="clear">
    <!-- Prompt Confirm -->
    <popup-prompt ref="prompt" />

    <!-- -->
    <v-form id="dialog-form" ref="form" :class="{ mobile: $vuetify.breakpoint.xs }" class="px-0 mx-0" @submit.prevent="">
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-text-field v-model="fields.label" label="Label"></v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <!-- -->
  </popup>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      fields: {
        label: '',
      },
      edit: null,
    };
  },
  computed: {
    isEditing() {
      return !!this.fields.id;
    },
  },
  methods: {
    open(title, edit) {
      const promise = this.$refs.popup.open({ title }, edit);
      this.edit = edit;
      this.clear();
      return promise;
    },
    close() {
      this.$refs.popup.close(null);
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.$refs.popup.close(this.fields);
      }
    },
    clear() {
      this.fields = this.$options.data().fields; // Assign defaults

      if (this.edit) {
        Object.assign(this.fields, this.edit); // Override with edited object fields
      }
    },
  },
};
</script>

<style scoped>
.border2 {
  border: 1px solid red;
}
</style>
