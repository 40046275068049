export default function (value, after, before) {
  const rounded = Math.round(value * 100) / 100;
  return (
    (before || '') +
    rounded
      .toString()
      .replace(',', '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (after || '')
  );
}
