// Nav defined in /store/modules/nav.js

import authenticate from '@/auth/authenticate';
import store from '@/store';

import Index from '@/views/index';

// Data
export default [
  {
    path: '/',
    component: Index,//() => import('@/views/index'),
    beforeEnter: authenticate,
    redirect: '/home',

    children: [
      {
        path: '/home',
        component: () => import('@/views/home')
      },
      /*{
        path: '/schedule',
        name: 'schedule',
        meta: {
          breadcrumb: [{ name: 'User' }, { name: 'Schedule' }]
        },
        component: () => import('@/views/flight/schedule')
      },*/
      {
        path: '/tachlog/:aircraft_id',
        name: 'log',
        props: true,
        meta: {
          breadcrumb: [{ name: 'User' }, { name: 'Tach Log' }]
        },
        component: () => import('@/views/user/tachlog')
      },
      /*{
        path: '/chips',
        name: 'chips',
        meta: {
          breadcrumb: [{ name: 'UiKits' }, { name: 'chips' }]
        },
        component: () => import('@/views/flight/chips')
      },*/
      {
        path: '/admin/aircraft',
        name: 'aircraft',
        meta: {
          breadcrumb: [{ name: 'Admin' }, { name: 'Aircraft' }]
        },
        component: () => import('@/views/admin/aircraft')
      },
      {
        path: '/admin/accounts',
        name: 'users',
        meta: {
          breadcrumb: [{ name: 'Admin' }, { name: 'Accounts' }]
        },
        component: () => import('@/views/admin/accounts')
      },
      {
        path: '/admin/certificates',
        name: 'certificates',
        meta: {
          breadcrumb: [{ name: 'Admin' }, { name: 'Certificates' }]
        },
        component: () => import('@/views/admin/certificates')
      },
      {
        path: '/admin/invoice',
        name: 'invoice',
        meta: {
          breadcrumb: [{ name: 'Admin' }, { name: 'Invoice' }]
        },
        component: () => import('@/views/admin/invoice')
      }
      /* {
        path: '/flight',
        component: () => import('@/views/flight/index'),
        children: [
          {
            path: 'schedule',
            name: 'schedule',
            meta: {
              breadcrumb: [{ name: 'Flight' }, { name: 'Schedule' }]
            },
            component: () => import('@/views/flight/schedule')
          },
          {
            path: 'log',
            name: 'log',
            meta: {
              breadcrumb: [{ name: 'Flight' }, { name: 'Log' }]
            },
            component: () => import('@/views/user/log')
          },
          {
            path: 'chips',
            name: 'chips',
            meta: {
              breadcrumb: [{ name: 'UiKits' }, { name: 'chips' }]
            },
            component: () => import('@/views/flight/chips')
          }
        ]
      }, {
        path: '/admin',
        component: () => import('@/views/flight/index'),
        children: [
          {
            path: 'aircraft',
            name: 'aircraft',
            meta: {
              breadcrumb: [{ name: 'Flight' }, { name: 'Schedule' }]
            },
            component: () => import('@/views/flight/schedule')
          },
          {
            path: 'users',
            name: 'users',
            meta: {
              breadcrumb: [{ name: 'Flight' }, { name: 'Log' }]
            },
            component: () => import('@/views/user/log')
          }
        ]
      } */
    ]
  },
  {
    path: '/session',
    component: () => import('@/views/session/Sessions'),
    beforeEnter: (to, from, next) => {
      const session = store.session.data;

      if (to.path.startsWith('/session/') && session.isLoggedIn) {
        next('/home');
      } else {
        next();
      }
    },
    children: [
      /* {
         path: 'sign-in',
         component: () => import('@/views/session/SignIn')
       },
      {
        path: 'error',
        component: () => import('@/views/session/Error')
      },
      {
        path: 'lockscreen',
        component: () => import('@/views/session/Lockscreen')
      },*/
      {
        path: 'login',
        name: 'login',
        component: () => import('@/views/session/Login')
      },
      {
        path: 'forgot',
        component: () => import('@/views/session/Forgot')
      },
      /*{
        path: 'sign-in-three',
        component: () => import('@/views/session/SignInThree')
      },
      {
        path: 'sign-in-four',
        component: () => import('@/views/session/SignInFour')
      },
      {
        path: 'sign-in-five',
        component: () => import('@/views/session/SignInFive')
      },*/
      {
        path: 'sign-up',
        component: () => import('@/views/session/SignUp')
      },
      {
        path: 'sign-up-2',
        name: 'signup',
        component: () => import('@/views/session/SignUpTwo')
      },
      {
        path: 'sign-up-5',
        component: () => import('@/views/session/SignUpFive')
      },
      {
        path: 'register',
        component: () => import('@/views/session/Register')
      }
    ]
  }
];
