const config = {
  mode: "live",
  live: {
    axios: {
      baseUrl: 'https://flyingclub.ocservers.com:4001/',
    }
  },
  demo: {
    axios: {
      baseUrl: 'https://demo.ocservers.com:5001/'
    }
  },
  shared: {
    afkMinutes: 5
  }
};

//
const data = config[config.mode];
data.mode = config.mode;
data.demo = data.mode == "demo";
Object.assign(data, config.shared);

export default data;