/*
 * ============================
 * File: index.js
 * Project:
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Saturday, 18th April 2020 9:32:52 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

// Imports
import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
// import authenticate from '@/auth/authenticate';

import routes from '@/router/routes.js';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    ...routes
    /* {
      path: '/',
      component: () => import('@/views/index'),
      beforeEnter: authenticate,
      redirect: '/flight/log',

      children: [
        {
          path: '/flight',
          component: () => import('@/views/flight/index'),
          children: [
            {
              path: 'schedule',
              name: 'schedule',
              meta: {
                breadcrumb: [{ name: 'Flight' }, { name: 'Schedule' }]
              },
              component: () => import('@/views/flight/schedule')
            },
            {
              path: 'log',
              name: 'log',
              meta: {
                breadcrumb: [{ name: 'Flight' }, { name: 'Log' }]
              },
              component: () => import('@/views/user/log')
            },
            {
              path: 'chips',
              name: 'chips',
              meta: {
                breadcrumb: [{ name: 'UiKits' }, { name: 'chips' }]
              },
              component: () => import('@/views/flight/chips')
            }
          ]
        }
      ]
    }, */
  ]
});

router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.
    // store.dispatch('state/setLoading', true);
  }
  next();
});

router.afterEach((to, from) => {
  // Remove initial loading
  // const gullPreLoading = document.getElementById("loading_wrap");
  // if (gullPreLoading) {
  //   gullPreLoading.style.display = "none";
  // }
  // Complete the animation of the route progress bar.
  // setTimeout(() => store.dispatch('state/setLoading', false), 500);
  /// /////////////////// store.dispatch('state/setLoading', false);
  // store.dispatch('state/setLoading', false);
  // NProgress.done();
  // if (isMobile) {
  // if (window.innerWidth <= 1200) {
  //   // console.log("mobile");
  //   store.dispatch("changeSidebarProperties");
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch("changeSecondarySidebarProperties");
  //   }
  // } else {
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch("changeSecondarySidebarProperties");
  //   }
  //   // store.state.sidebarToggleProperties.isSecondarySideNavOpen = false;
  // }
});

export default router;
