import store from '@/store';

export default (to, from, next) => {
  const session = store.session.data;//store.get('session/data');

  if (!session.isLoggedIn || (!session.stayLoggedIn && Date.now() >= session.timeout)) {
    //store.dispatch('session/clear');
    store.session.clear();
    next('/session/login');
  } else {
    next();
  }

  /* if (localStorage.getItem('session') != null && localStorage.getItem('session').length > 0) {
    next();
  } else {
    localStorage.removeItem('session');
    next('/session/login');
  } */
};
