import Vuex from 'vuex';
import Vue from 'vue';

import themeConfig from './modules/themeConfig';
import session from './modules/session/session';
import nav from './modules/nav';
import state from './modules/state';
import alert from './modules/alert';

import logs from './modules/views/logs';

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
  modules: {
    themeConfig,
    session,
    nav,
    state,
    alert,
    logs
  }
});
