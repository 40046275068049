import themeConfig from '@/themeConfig';
import themePreset from '@/themePreset';
import vuetify from '@/plugins/vuetify';

const $vuetify = vuetify.framework;

const state = {
  themeMode: themeConfig,
  vuetifyPreset: themePreset
};

const themeModeCache = null;
const getters = {
  getThemeMode: state => state.themeMode,
  getVuetifyPreset: state => state.vuetifyPreset,
  isSidebarOpen: state => state.themeMode.verticalSidebarDrawer
};

const actions = {
  changeThemeMode({ commit }) {
    commit('toggleThemeMode');
  },
  changeThemeLayout({ commit }, data) {
    commit('toggleThemeLayout', data);
  },
  setThemeDarkMode({ commit }, data) {
    commit('setThemeDarkMode', data);
  },
  toggleThemeDarkMode({ commit }, data) {
    commit('toggleThemeDarkMode', data);
  },
  changeThemeRtl({ commit }) {
    commit('toggleThemeRtl');
  },
  toggleNav({ commit }) {
    commit('toggleNav');
  },
  changeVerticalSaasSidebarDrawer({ commit }) {
    commit('toggleVerticalSaasSidebarDrawer');
  },
  changepanelColor({ commit }, data) {
    commit('togglepanelColor', data);
  },
  changeAppBarColor({ commit }, data) {
    commit('toggleAppBarColor', data);
  },
  changeCompactVerticalSidebarDrawer({ commit }) {
    commit('toggleCompactVerticalSidebarDrawer');
  },
  toggleNavMini({ commit }) {
    commit('toggleVerticalSidebarMini');
  }
};

const mutations = {
  toggleThemeLayout(state, data) {
    state.themeMode.layout = data;
  },
  toggleThemeRtl(state) {
    state.themeMode.rtl = !state.themeMode.rtl;
  },
  toggleNav(state) {
    state.themeMode.verticalSidebarDrawer = !state.themeMode.verticalSidebarDrawer;
  },
  toggleVerticalSaasSidebarDrawer(state) {
    state.themeMode.verticalSaasSidebarDrawer = !state.themeMode.verticalSaasSidebarDrawer;
  },
  togglepanelColor(state, data) {
    state.themeMode.panelColor = data;
  },
  toggleAppBarColor(state, data) {
    state.themeMode.appBarColor = data;
  },
  toggleVerticalSidebarMini(state) {
    state.themeMode.verticalSidebarMini = !state.themeMode.verticalSidebarMini;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
