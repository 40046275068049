import Vue from 'vue';
import store from '@/store';

const $afk = {
  async install(Vue) {
    let hadInput = false;
    document.addEventListener('mousedown', (event) => {
      store.session.resetTimeout();
      hadInput = true;
    });

    let first = true;
    function tick() {
      if (!first && !store.session.data.stayLoggedIn) {
        if (hadInput) {
          store.session.save();
          hadInput = false;
        } else {
          if (Date.now() >= store.session.data.timeout) {
            store.session.logout();
          }
        }
      } else {
        first = false
      }

      setTimeout(tick, 10 * 1000);
    }

    tick();
  },
};
//Vue.prototype.$afk = $afk;

export default $afk;
