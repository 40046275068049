// Locale
import colors from 'vuetify/lib/util/colors';
import vuetify from '@/plugins/vuetify';

const $vuetify = vuetify.framework;

const themeConfig = {
  disableCustomizer: false, // options[Boolean] : true, false(default)

  rtl: false, // options[Boolean] : true, false(default)
  verticalSidebarMini: false, // options[Boolean] : true, false(default)
  verticalSidebarDrawer: true, // options[Boolean] : true, false(default)
  showBreadcrumb: true, // options[Boolean] : true, false(default)

  layout: 'PrimaryLayout', // VerticalCompactSidebar,VerticalSaasLayout,VerticalSidebar,HorizontalBar

  appBarColor: 'white', // primary,white,sucsess,warning,etc
  isAppbarTransparent: true
};

export default themeConfig;
