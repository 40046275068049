// Find and import all files
const paths = require.context('@/filters', true, /\.js$/);
const files = [];
for (const file of paths.keys()) {
  const name = file.replace(/^\.\//, '').replace(/\.\w+$/, '');
  if (name !== 'index') {
    files.push([name, import(`@/filters/${name}.js`)]);
  }
}

export default {
  async install(Vue) {
    // Initialize all files and associate by basename
    for (const [file, exported] of files) {
      Vue.filter(file, (await exported).default);
    }
  }
};
