import axios from 'axios';
import store from '@/store';

export default {
  async insert(data) {
    const output = (await axios.post('tachlog_insert', { data })).data;

    if (output.error) {
      const error = new Error(output.error);
      error.stack = output.stack;

      store.alert.error(error);
      //store.alert.set({ type: "error", message: output.error, timeout: 3000 });
    }

    return output.result;
  },
  async update(data) {
    const output = (await axios.post('tachlog_update', { data })).data;

    if (output.error) {
      const error = new Error(output.error);
      error.stack = output.stack;

      store.alert.error(error);
    }

    return output.result;
  },
  async select_month(data) {
    this.offsetLast = data.offset = data.offset || 0;

    const output = (await axios.post('tachlog_select_month', { data })).data;

    if (output.error) {
      const error = new Error(output.error);
      error.stack = output.stack;

      store.alert.error(error);
    }

    if (output.offset !== this.offsetLast) {
      return null;
    }

    /*return output.results.map((entry) => {
      return { id: entry[0], timestamp: entry[1], account_id: entry[2], aircraft_id: entry[3], tach_start: entry[4], tach_end: entry[5], fuel_down: entry[6], oil: entry[7], remarks: entry[8], is_removed: entry[9], flagged: entry[10], tach_duration: entry[11] };
    });*/
    return output.results;
  },
  async select_before(data) {
    const output = (await axios.post('tachlog_select_before', { data })).data;

    if (output.error) {
      const error = new Error(output.error);
      error.stack = output.stack;

      store.alert.error(error);
    }

    return output.result;
  },
  async select_latest(data) {
    const output = (await axios.post('tachlog_select_latest', { data })).data;

    if (output.error) {
      const error = new Error(output.error);
      error.stack = output.stack;

      store.alert.error(error);
    }

    return output.result;
  },
};
