// Locale
// import { en } from '../../locale'
import colors from 'vuetify/lib/util/colors';
import navBarTheme from '@/components/layout/nav/nav-theme.js';

const mq = window.matchMedia('(prefers-color-scheme: dark)');

// export const vuetify = new Vuetify({
//   theme: { dark: mq.matches }
// });

// console.log(mq);

// mq.addEventListener("change", e => {
//   console.log(mq);
//   this.$vuetify.theme.dark = e.matches;
// });
const themePreset = {
  breakpoint: {
    scrollBarWidth: 16,
    thresholds: {
      xs: 600,
      sm: 960,
      md: 1280,
      lg: 1920
    }
  },

  rtl: false,

  theme: {
    dark: mq.matches,

    default: 'light',
    disable: false,
    options: {
      cspNonce: undefined,
      customProperties: true,
      minifyTheme: undefined,
      themeCache: undefined
    },
    themes: {
      light: {
        primary: colors.blue,
        secondary: '#304156',
        success: colors.green,
        danger: colors.red,
        warning: colors.deepOrange,
        info: colors.indigo,

        dark: '#242939',

        background: '#f2f3f8',

        border: colors.grey.lighten2,

        card: colors.shades.white,
        cardTitle: colors.shades.white // colors.grey.lighten4
      },
      dark: {
        primary: colors.blue,
        secondary: '#304156',
        success: colors.green,
        danger: colors.red,
        warning: colors.deepOrange,
        info: colors.indigo,

        border: colors.grey.darken2,

        card: '#363636',
        cardTitle: '#444444'
      }
    }
  }
};

export default themePreset;

function loadTheme(themesFunct) {
  const theme = themesFunct(themePreset.theme.themes);

  // Assign styles
  for (const [style, data] of Object.entries(theme.styles)) {
    Object.assign(themePreset.theme.themes[style], data);
  }

  // Assign config
  Object.assign(themePreset.theme.options, theme.options);
}

// Append themes
loadTheme(navBarTheme);
