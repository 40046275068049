import axios from 'axios';
import store from '@/store';

export default {
  async get_all(data) {
    const output = (await axios.post('status_get_all', {
      data: { account_id: data.account_id }
    })).data;

    if (output.error) {
      const error = new Error(output.error);
      error.stack = output.stack;

      store.alert.error(error);
    }

    return output.result;
  },
};
