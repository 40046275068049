// Define $vuetify.breakpoint.landscape

export default {
  data() {
    return { isLandscape: false };
  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize);

    Object.defineProperty(this.$vuetify.breakpoint, 'landscape', {
      get: () => this.isLandscape
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize(event) {
      this.isLandscape = window.innerWidth > window.innerHeight;
    }
  }
};
