<template>
  <v-dialog
    v-model="opened"
    :max-width="options.maxWidth || maxWidth"
    :style="{ 'z-index': options.zIndex || zIndex }"
    v-bind="$props"
    v-on="$listeners"
    @keydown.esc="onClose"
  >
    <v-card>
      <v-toolbar :dark="$vuetify.theme.dark" :color="color || theme.cardTitle" flat height="76px">
        <!-- row -->
        <v-row no-gutters justify="center" class="align-center" :class="`mx-${paddingTitle}`">
          <v-col class="text-left">
            <v-toolbar-title class="text-body-3">
              {{ options.title || title }}
            </v-toolbar-title>
          </v-col>

          <v-col class="text-center"> </v-col>

          <v-col v-if="exitable" class="text-right">
            <v-btn class="mr-n2" height="36" width="36" icon color tabindex="-1" @click.stop="onClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <!-- -->
      </v-toolbar>

      <v-divider />

      <!-- :class="`py-${padding}`"  -->
      <template v-if="$slots.prepend">
        <div class="px-7" :class="`pt-${padding - 3}`">
          <slot name="prepend" />
        </div>

        <v-divider />
      </template>

      <template v-if="$slots.default">
        <div class="px-7" :class="`pt-${padding - 3}`">
          <slot name="default" />
        </div>
      </template>

      <template v-if="$slots.append">
        <v-divider />

        <div class="px-7" :class="`pt-${padding - 3}`">
          <slot name="append" />
        </div>

        <v-divider class="pb-5" />
      </template>

      <v-container :class="`px-${padding} pb-${padding}`">
        <div>
          <!--  :class="`mt-${padding}`" -->
          <v-row no-gutters justify="center" class="align-center ma-0">
            <slot v-if="$slots.footer" name="footer" />

            <template v-else>
              <v-col class="text-left">
                <v-btn elevation="0" color="red darken-1" style="background-color: #ffecec" text @click="$emit('on-clear')">
                  Clear
                </v-btn>
              </v-col>

              <v-col class="text-right">
                <v-btn elevation="0" color="primary" dark @click="$emit('on-submit')"> Submit </v-btn>
              </v-col>
            </template>
          </v-row>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    maxWidth: {
      type: String,
      default: '200px',
    },
    zIndex: {
      type: Number,
      default: 200,
    },
    title: {
      type: String,
      default: '',
    },
    padding: {
      type: Number,
      default: 7,
    },
    paddingTitle: {
      type: Number,
      default: 3,
    },
    exitable: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      opened: false,
      options: {
        resolve: null,
        title: '',
        returnDefault: false,
      },
    };
  },
  computed: {
    theme() {
      return this.$vuetify.theme.themes[this.$vuetify.theme.dark ? 'dark' : 'light'];
    },
  },
  methods: {
    open(options) {
      this.options = Object.assign(this.options, options);
      this.opened = true;

      // Call and assign any functions in this.object for dynamic values
      for (const [key, value] of Object.entries(this.options)) {
        if (value instanceof Function) {
          this.options[key] = value();
        }
      }

      const promise = new Promise((resolve, reject) => {
        this.options.resolve = resolve;
      });

      return promise;
    },
    close(result) {
      this.options.resolve(result || null);
      this.opened = false;
    },
    onClose() {
      if (this.exitable) {
        this.$emit('on-close');
        this.close(this.options.returnDefault);
      }
    },
  },
};
</script>

<style scoped>
.border2 {
  border: 1px solid red;
}
</style>
