<template>
  <popup ref="popup" exitable persistent max-width="600px" v-on="$listeners" @on-submit="submit" @on-clear="clear">
    <!-- Prompt Confirm -->
    <popup-prompt ref="prompt" />

    <template v-slot:append>
      <v-row>
        <v-col class="mt-3" style="height: 58px" sm="6" md="6">
          <v-btn
            :color="fields.has_tachlog ? 'green' : 'grey'"
            :dark="!!fields.has_tachlog"
            elevation="0"
            :outlined="!fields.has_tachlog"
            block
            @click="fields.has_tachlog = !fields.has_tachlog"
          >
            Has Tach Log
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6" md="6"></v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field v-model="fields.label" label="Label" autocomplete="one-time-code" :disabled="!fields.has_tachlog" />
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field v-model="fields.index" label="Index" autocomplete="one-time-code" :disabled="!fields.has_tachlog" />
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <icon-field
            v-model="fields.icon"
            :disabled="!fields.has_tachlog"
            :color="fields.color === '#FFFFFF' ? 'primary' : fields.color"
          />
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <color-field v-model="fields.color" label="Color" noalpha null="#FFFFFF" :disabled="!fields.has_tachlog" />
        </v-col>
      </v-row>
    </template>

    <!-- -->
    <v-form id="dialog-form" ref="form" :class="{ mobile: $vuetify.breakpoint.xs }" class="px-0 mx-0" @submit.prevent="">
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-text-field v-model="fields.id" label="Tail #" autocomplete="one-time-code" />
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field v-model="fields.model" label="Model" autocomplete="one-time-code" />
        </v-col>

        <!--
  <v-col cols="12" sm="6" md="6">
    <v-select v-model="fields.groups" :items="selectGroups" label="Groups" multiple />
  </v-col>
-->
      </v-row>
    </v-form>
    <!-- -->
  </popup>
</template>

<script>
export default {
  data() {
    return {
      showPassword: false,
      selectGroups: ['None'],
      selectTypes: ['Regular', 'Moderator', 'Admin'],
      selectCertificates: [
        { text: 'Ins. Private', value: 1 },
        { text: 'Ins. ATP', value: 2 },
        { text: 'Ins. Commercial', value: 3 },
        { text: 'Ins. Commercial Helicoptor', value: 4 },
        { text: 'CFI', value: 5 },
        { text: 'CFII', value: 6 },
        { text: 'MEI', value: 7 },
        { text: 'Student', value: 8 },
        { text: 'Advanced Ground Instructor', value: 9 },
      ],
      fields: {
        id: '',
        model: '',
        has_tachlog: true,
        label: '',
        index: 0,
        icon: 'mdi-airplane',
        color: '#FFFFFF',
      },
      edit: null,
    };
  },
  computed: {
    isEditing() {
      return !!this.fields.id;
    },
  },
  methods: {
    open(title, edit) {
      const promise = this.$refs.popup.open({ title }, edit);
      this.edit = edit;
      this.clear();
      return promise;
    },
    close() {
      this.$refs.popup.close(null);
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.$refs.popup.close(this.fields);
      }
    },
    clear() {
      this.fields = this.$options.data().fields; // Assign defaults

      if (this.edit) {
        Object.assign(this.fields, this.edit); // Override with edited object fields
      }
    },
  },
};
</script>

<style scoped>
.border2 {
  border: 1px solid red;
}
</style>
