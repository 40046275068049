// JS: $vuetify.theme.themes[light|dark].navBarField
// or
// JS: getThemeMode[light|dark].navBarField
// CSS: var(--v-navBarField-base)

import colors from 'vuetify/lib/util/colors';

export default function(themes) {
  const shared = {
    navBarBorderAccent: '#ff7348'
  };

  return {
    options: {
      // Only VUE3 supports non-color CSS entries
      navBarItemHeight: '56px'
    },
    styles: {
      light: {
        ...shared,
        navBarBorder: themes.light.border,

        navBarPanelHeader: themes.light.card, // Nav background at the top
        navBarPanel: themes.light.card, // Nav background and base item background color
        navBarItemContent: colors.grey.darken3, // Base item text color
        navBarItemContentHighlight: colors.grey.darken3, // Highlighted item text color

        navBarItemHighlight: themes.light.card, // transparent // Highlighted item background color
        navBarGroupHighlight: themes.light.card, // transparent // Highlighted item background color

        navBarItemIcon: colors.grey.darken2, // Base item icon color
        navBarItemIconHighlight: colors.grey.darken3 // Highlighted item icon color
      },
      dark: {
        ...shared,
        navBarBorder: themes.dark.border,

        navBarPanelHeader: colors.grey.darken3,
        navBarPanel: themes.dark.card,
        navBarItemContent: colors.shades.white,
        navBarItemContentHighlight: colors.shades.white,

        navBarItemHighlight: '#525252',
        navBarGroupHighlight: '#363636',

        navBarItemIcon: colors.shades.white,
        navBarItemIconHighlight: colors.shades.white
      }
    }
  };
}

/*

      light: {
        ...shared,
        navBarBorder: themes.light.border,

        navBarPanelHeader: themes.light.card, // Nav background at the top
        navBarPanel: themes.light.card, // Nav background and base item background color
        navBarItemContent: colors.grey.darken3, // Base item text color
        navBarItemContentHighlight: colors.grey.darken3, // Highlighted item text color

        navBarItemHighlight: themes.light.card, // transparent // Highlighted item background color
        navBarGroupHighlight: themes.light.card, // transparent // Highlighted item background color

        navBarItemIcon: colors.grey.darken2, // Base item icon color
        navBarItemIconHighlight: colors.grey.darken3 // Highlighted item icon color
      },
      dark: {
        ...shared,
        navBarBorder: themes.dark.border,

        navBarPanelHeader: colors.grey.darken3,
        navBarPanel: themes.dark.card,
        navBarItemContent: colors.shades.white,
        navBarItemContentHighlight: colors.shades.white,

        navBarItemHighlight: '#525252',
        navBarGroupHighlight: '#363636',

        navBarItemIcon: colors.shades.white,
        navBarItemIconHighlight: colors.shades.white
      }

      */
