//import authFirebase from './auth/firebase';
import authCustom from './auth/custom';
import router from '@/router';
import config from '@/config';

const defaultLocalStorage = {
  isLoggedIn: false,
  stayLoggedIn: false,
  account_id: null,
  session_id: null,
  type_id: 0,
  // isDark: false, // Don't assume it's false at login
  name_first: null,
  name_last: null,
  name_initials: 'G',
  started: 0,
  timeout: 0
};

function getLocalStorage() {
  return localStorage.getItem('session') ? JSON.parse(localStorage.getItem('session')) : null;
}

const session = {
  namespaced: true,
  modules: [
    // Select one auth handler (login/logout/register)
    // authFirebase
    authCustom
  ],
  state: {
    data: { ...defaultLocalStorage, ...getLocalStorage() }
  },
  getters: {
    data: state => state.data,
    isMod: state => state.data.type_id == 2 || state.data.type_id == 3,
    isAdmin: state => state.data.type_id == 3,
    isUser: state => state.data.type_id == 1,
    isGuest: state => state.data.type_id == 0,
  },
  mutations: {
    set(state, payload) {
      Object.assign(state.data, payload);
    },
    clear(state) {
      Object.assign(state.data, defaultLocalStorage);
      localStorage.removeItem('session');

      state.loading = false;
      // this.$router.go("/"); // In your code
    },
    save(state) {
      localStorage.setItem('session', JSON.stringify(state.data));
    },
    resetTimeout(state) {
      state.data.timeout = Date.now() + (config.afkMinutes * 60 * 1000);
    },
    logout(state) {
      session.mutations.clear(state);

      if (router.currentRoute.path != '/session/login') {
        router.push('/session/login');
      }
    }
  },
  actions: {
    set({ commit }, payload) {
      commit('set', payload);
    },
    clear({ commit }) {
      commit('clear');
    },
    save({ commit }) {
      commit('save');
    },
    resetTimeout({ commit }) {
      commit('resetTimeout');
    },
    logout({ commit }) {
      commit('logout');
    },
  }
};

export default session;
