<template>
  <v-avatar
    :size="size"
    :color="$vuetify.theme.dark ? 'grey lighten-2' : 'grey darken-1'"
    class="rounded-circle"
    @click="toggleThemeDarkMode()"
  ></v-avatar>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: '45'
    }
  },
  methods: {
    toggleThemeDarkMode() {
      // this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.$vuetify.theme.setDark(!this.$vuetify.theme.dark);

      this.$emit('click');
    }
  }
};
</script>

<style scoped>
.rounded-circle {
  cursor: pointer;
}
</style>
