// Routes defined in /router/routes.js

import store from '@/store';
import colors from 'vuetify/lib/util/colors';

let interval = null;

function createNavTop(appendAircraft) {
  return [
    {
      disabled: true,
      subheader: 'Apps',
      icon: 'mdi-apps-box',
      divider: 'mobile' // 'mobile'
    },

    {
      title: 'Home',
      icon: 'mdi-home-outline',
      to: '/home'
    },
    {
      __model: true,
      group: 'log',
      title: 'Log',
      icon: 'mdi-pencil-outline',

      children: [...appendAircraft]
    },
    /* {
      group: 'flight',
      title: 'Flight',
      icon: 'mdi-airplane-takeoff',
  
      children: [
        {
          title: 'Log',
          icon: 'mdi-pencil-outline',
          to: '/flight/log'
        },
        {
          title: 'Schedule',
          icon: 'mdi-chart-gantt',
          to: '/flight/schedule'
        }
      ]
    } */ {
      group: 'admin',
      title: 'Admin',
      icon: 'mdi-lock-outline',
      color: colors.red.darken1,
      type_ids: [3],

      children: [
        {
          title: 'Aircraft',
          icon: 'mdi-airplane',
          to: '/admin/aircraft'
        },
        {
          title: 'Users',
          icon: 'mdi-account-multiple-outline',
          to: '/admin/accounts'
        },
        {
          title: 'Certificates',
          icon: 'mdi-medal-outline',
          to: '/admin/certificates'
        },
        {
          title: 'Invoice',
          icon: 'mdi-script-text-outline',
          to: '/admin/invoice'
        }
      ]
    },
    {
      disabled: true,
      group: 'session',
      title: 'Session',
      icon: 'mdi-login-variant',

      children: [
        {
          title: 'Error',
          to: '/session/error'
        },
        {
          title: 'Forgot Pass',
          to: '/session/forgot'
        },
        {
          title: 'Lockscreen',
          to: '/session/lockscreen'
        },
        {
          title: 'Login 2',
          to: '/session/login'
        },
        {
          title: 'Login 3',
          to: '/session/sign-in-three'
        },
        {
          title: 'Login 4',
          to: '/session/sign-in-four'
        },
        {
          title: 'Login 5',
          to: '/session/sign-in-five'
        },
        {
          title: 'Register',
          to: '/session/sign-up'
        },
        {
          title: 'Register 2',
          to: '/session/sign-up-2'
        },
        {
          title: 'Register 3',
          to: '/session/sign-up-5'
        },
        {
          title: 'Register 4',
          to: '/session/register'
        }
      ]
    }
  ];
}

const state = {
  message: '',
  messages: '',
  messagesIndex: 0,
  data: {
    isVerticalSidebar: true,
    isVerticalCompact: false
  },
  itemsTop: createNavTop([]),
  itemsBottom: [],
  pathMessages: {}
};

const getters = {
  getNav: state => state,
  getPathMessages: state => state.pathMessages,
  getMessage: state => state.message
};

const actions = {
  setMessage(ignore, data) {
    store.mutate('nav/setMessage', data || '');
  },
  refresh(ignore, $api) {
    //store.nav.refreshItemsTop($api)
    return Promise.all([store.action('nav/refreshItemsTop', $api), store.action('nav/refreshItemsBottom', $api)]);
  }
};

function setMessages(arr) {
  for (const item of arr) {
    if (item.message) {
      state.pathMessages[item.to] = item.message;
    }

    if (item.children?.length) {
      setMessages(item.children);
    }
  }

  return null;
}

const mutations = {
  setMessage(state, data) {
    if (state.interval) {
      clearInterval(state.interval);
      interval = null;
    }

    if (data instanceof Array) {
      state.messagesIndex = 0;
      state.message = data[0];

      interval = setInterval(() => {
        state.message = '';
        setTimeout(() => {
          state.messagesIndex = ++state.messagesIndex % data.length;
          state.message = data[state.messagesIndex];
        }, 100);
      }, 2000);
    } else {
      state.message = data;
    }
  },
  setItemsTop(state, data) {
    state.itemsTop = data;
    setMessages(data);
  },
  setItemsBottom(state, data) {
    state.itemsBottom = data;
    setMessages(data);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

// Create nav item functions
actions.refreshItemsTop = async function ({ commit }, $api) {
  // Generate dynamic content
  const appendAircraft = [];
  if ($api) {
    const aircrafts = await $api.aircraft.select_all_minimal();
    for (const aircraft of aircrafts) {
      if (!aircraft.has_tachlog) continue;

      appendAircraft.push({
        title: aircraft.label,
        message: aircraft.label,
        icon: aircraft.icon,
        to: `/tachlog/${aircraft.id}`,
        color: aircraft.color,
        colorHighlight: aircraft.color_highlight
      });
    }
  }

  store.mutate('nav/setItemsTop', createNavTop(appendAircraft));
};

actions.refreshItemsBottom = async function ($api) {
  const data = [
    {
      disabled: true,
      divider: false
    },
    {
      title: 'Support',
      icon: 'mdi-help-circle-outline',
      //to: '/support',
      click: "openSupport"
    }
  ];

  store.mutate('nav/setItemsBottom', data);
};
