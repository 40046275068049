<template>
  <popup ref="popup" exitable persistent max-width="600px" v-on="$listeners" @on-submit="submit" @on-clear="clear">
    <!-- Prompt Confirm -->
    <popup-prompt ref="prompt" />

    <!-- -->
    <v-form id="dialog-form" ref="form" :class="{ mobile: $vuetify.breakpoint.xs }" class="px-0 mx-0" @submit.prevent="">
      <v-row>
        <v-col cols="12" sm="12" md="12" class="pb-0">
          <v-select v-model="fields.target_account_id" :items="selectAccountsTarget" label="To" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" md="12" class="pt-0 pb-6">
          <v-text-field v-model="fields.title" label="Title"></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-textarea outlined v-model="fields.message" class="mx-3"></v-textarea>
      </v-row>
    </v-form>
    <!-- -->
  </popup>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      accountsTargetRaw: [],
      fields: {
        title: '',
        message: '',
      },
      edit: null,
    };
  },
  computed: {
    isEditing() {
      return !!this.fields.id;
    },
    selectAccountsTarget() {
      return this.accountsTargetRaw.map((account) => {
        return {
          text: account.name_first + ' ' + account.name_last,
          value: account.id,
        };
      });
    },
  },
  methods: {
    open(title, edit) {
      const promise = this.$refs.popup.open({ title }, edit);
      this.edit = edit;
      this.clear();
      return promise;
    },
    close() {
      this.$refs.popup.close(null);
    },
    async submit() {
      this.$refs.popup.close(this.fields);
    },
    clear() {
      this.fields = this.$options.data().fields; // Assign defaults

      if (this.edit) {
        Object.assign(this.fields, this.edit); // Override with edited object fields
      }
    },
  },
  async mounted() {
    this.accountsTargetRaw = await this.$api.account.select_all_support();
  },
};
</script>

<style scoped>
.border2 {
  border: 1px solid red;
}
</style>
