const state = {
  isLoading: false
};

const getters = {
  //state: state => state,
  isLoading: state => state.isLoading
};

const actions = {
  setLoading({ commit }, payload) {
    commit('setLoading', payload);
  }
};

const mutations = {
  setLoading(state, payload) {
    state.isLoading = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
