/*
 * ============================
 * File: main.js
 * Project:
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Tuesday, 14th April 2020 7:17:10 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import vuetify from './plugins/vuetify';
import mixins from './mixins';
import plugins from './plugins';
import components from './components';

// mock
import './fake-db/index.js';
// if (process.env.NODE_ENV === "development") {
//   makeServer();
// }

Vue.use(plugins);
Vue.use(components);
Vue.config.productionTip = false;

new Vue({
  store,
  router,
  vuetify,
  mixins,
  render: h => h(App)
}).$mount('#app');
