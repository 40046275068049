import store from '@/store';

export default {
  namespaced: true,
  state: {
    entries: [],
    month: 0,
    year: 0
  },
  getters: {
    getAll: state => state.entries
  },
  actions: {
    async fetchAll({ state }, { aircraftId, year, month }) {
      store.mutate('logs/setEntries', { entries: [], month: 0, year: 0 });
      store.mutate('logs/setEntries', { entries: await remote.fetchAll(aircraftId, year, month), month, year });
      return state.entries;
    },
    async fetch({ state }, id) {
      const newest = await remote.fetch(id);

      if (newest) {
        const original = await store.action('logs/get');
        store.mutate('logs/updateEntry', { target: original, source: newest });
      }

      return newest;
    },
    get({ state }, id) {
      return state.entries.find(entry => entry.id === id);
    },
    async setFlagged({ state }, { id, value }) {
      remote.setFlagged(id, value);
      await store.action('logs/fetch', id);
    },
    async toggleFlagged({ state }, { id }) {}
  },
  mutations: {
    setEntries(state, data) {
      state.entries = data.entries;
      state.year = data.year;
      state.month = data.month;
    },
    updateEntry(state, data) {
      if (data.target) {
        Object.assign(data.target, data.source); // Update existing
      }
    }
  }
};

// Remote
const remote = {
  data: {
    entries: [
      {
        id: 1,
        pos: 1,
        aircraftId: '54589',
        accountId: 1,
        date: new Date('2016-02-17T16:40:30'),
        user: 'First Doe',
        tachStart: 6,
        tachEnd: 10,
        fuelFull: true,
        oil: 0.1,
        remarks: 'Some remarks',
        flagged: false,

        year: 2021,
        month: 6
      },
      {
        id: 3,
        pos: 2,
        aircraftId: '54589',
        accountId: 1,
        date: new Date('2021-06-12T16:40:30'),
        user: 'Second Roe',
        tachStart: 4,
        tachEnd: 6,
        fuelFull: true,
        oil: 0.1,
        remarks: 'Some remarks',
        flagged: false,

        year: 2021,
        month: 6
      },
      {
        id: 6,
        pos: 3,
        aircraftId: '54589',
        accountId: 1,
        date: new Date('2017-03-12T16:40:30'),
        user: 'Turd Doe',
        tachStart: 1.5,
        tachEnd: 4,
        fuelFull: true,
        oil: 0.1,
        remarks: 'Some remarks',
        flagged: false,

        year: 2021,
        month: 6
      }
    ]
  },
  filter(aircraftId, year, month) {
    return this.data.entries.filter(
      entry => entry.aircraftId === aircraftId && entry.year === year && entry.month === month
    );
  },
  fetchAll(aircraftId, year, month) {
    return this.filter(aircraftId, year, month); // .sort((a, b) => b.pos - a.pos);
  },
  fetch(id) {
    return this.data.entries.find(entry => entry.id === id);
  },
  async setFlagged(id, value) {
    const entry = await this.fetch(id); // Db
    entry.flagged = value;
  }
};
