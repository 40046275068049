// Imported from './src/main.js'

// Import plugins
import pVuexSimplified from '@/plugins/vuex-simplified.js';
import pFilters from '@/plugins/filters.js';
import pApi from '@/plugins/api.js';
import pConfig from '@/plugins/config.js';
import pAfk from '@/plugins/afk.js';
import pAxios from 'axios';
import VueAxios from 'vue-axios';

const config = pConfig.data;

export default {
  async install(Vue) {
    // Plugins
    Vue.use(VueAxios, pAxios);
    Vue.axios.defaults.baseURL = config.axios.baseUrl;
    pAxios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    pAxios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET, POST, PATCH, PUT, DELETE, OPTIONS';
    pAxios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, Content-Type, X-Auth-Token';

    Vue.use(pConfig);
    Vue.use(pVuexSimplified);
    Vue.use(pFilters);
    Vue.use(pApi);
    Vue.use(pAfk);
  }
};
