import Vue from 'vue';

Date.prototype.mysql = function () {
  return Date.mysql(this); // Aka fecha.format(this, "YYYY-MM-DD HH:mm:ss");
}

Date.mysql = function (date) {
  return (date instanceof Date ? date.toISOString() : date).slice(0, 19).replace('T', ' ');
}

// Find and import all files
const paths = require.context('@/api', true, /\.js$/);
const files = [];
for (const file of paths.keys()) {
  const name = file.replace(/^\.\//, '').replace(/\.\w+$/, '');
  if (name !== 'index') {
    files.push([name, import(`@/api/${name}.js`)]);
  }
}

const $api = {
  async install(Vue) {
    // console.log(Object.keys(Vue.axios));
    // console.log(Vue.axios);
    // Initialize all files and associate by basename

    for (const [file, exported] of files) {
      const module = (await exported).default;
      module.$api = this;
      this[file] = module;
    }
  },
};
Vue.prototype.$api = $api;

export default $api;
