import axios from 'axios';

export default {
  async insert(data) {
    return await axios.post('aircraft_insert', { data });
  },
  async update(data) {
    return await axios.post('aircraft_update', { data });
  },
  async select_all() {
    return (await axios.post('aircraft_select_all')).data.results;
  },
  async select_all_minimal() {
    return (await axios.post('aircraft_select_all_minimal')).data.results.map((aircraft) => {
      const [id, model, label, has_tachlog, icon, color, color_highlight] = aircraft;
      return { id, model, label, has_tachlog, icon, color, color_highlight };
    });
  },
};
