const state = {
  alert: {
    message: '',
    type: 'error',
    dismissible: false
  }
};

const getters = {
  state: state => state.alert
};

const actions = {
  set({ commit }, payload) {
    commit('set', payload);
  },
  clear({ commit }) {
    commit('clear');
  },
  error({ commit }, payload) {
    commit('error', payload);
  }
};

let timeout = null;
const mutations = {
  set(state, payload) {
    // Remove last
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }

    // Process exception
    let message = payload.message;

    if (payload.message instanceof Error) {
      console.error(payload.message);
      message = payload.message.stack || payload.message.message;

      message = message.replaceAll('webpack-internal:///', '').replace('Error', '<b>Error</b>');
    }

    //
    state.alert.message = message;
    state.alert.type = payload.type;

    // Remove after time
    if (payload.timeout) {
      timeout = setTimeout(() => {
        state.alert.message = '';
        timeout = null;
      }, payload.timeout);
    }
  },
  clear(state) {
    state.alert.message = '';
  },
  // Define types
  error(state, message) {
    mutations.set(state, { type: "error", message, timeout: 4000 });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
