<template>
  <v-app>
    <!-- <keep-alive> -->
    <component :is="getThemeMode.layout"></component>
    <!-- </keep-alive> -->

    <!--
    <customizer />
    -->
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
    // Customizer: () => import('@/components/layout/customizer')
  },
  data() {
    return {
      layoutName: '',
    };
  },
  computed: {
    ...mapGetters(['getThemeMode']),
  },
  // mounted() {
  //   if (this.$route.params.layout) {
  //     this.changeThemeLayout(this.$route.params.layout);
  //   }
  // },
};
</script>
