// Define $vuetify.isAtTop

export default {
  data() {
    return { isAtTop: true };
  },
  mounted() {
    this.updateScroll();
    window.addEventListener('scroll', this.updateScroll, { passive: true });

    Object.defineProperty(this.$vuetify, 'isAtTop', {
      get: () => this.isAtTop
    });
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.updateScroll, { passive: true });
  },
  methods: {
    updateScroll() {
      this.isAtTop = window.scrollY <= 1;
    }
  }
};
