import store from '@/store';
import $api from '@/plugins/api';

export default {
  actions: {
    async login({ commit, getters }, data) {
      if (data.account_id && data.account_id > 0) {
        // Alternative login method
        const auth = { account_id: data.account_id, session_id: 'HO4FKSrU9Mex4twvFTCCZV2C8Y43' };

        try {
          let result = await $api.account.login({ id: data.account_id, password: data.password });

          //
          if (result) {
            store.session.set({
              isLoggedIn: true,
              stayLoggedIn: data.stayLoggedIn,
              account_id: result.id,
              session_id: result.session_id,
              type_id: result.type_id,
              name_first: result.name_first,
              name_last: result.name_last,
              name_initials: result.name_initials,
              started: Date.now()
            });

            store.session.resetTimeout();

            store.session.save();

            store.alert.clear();

            return;
          }
          //
        } catch (error) {
          console.log(error);
          store.alert.set({ message: error.message, type: 'error', timeout: 4000 });
        }
      }

      store.session.clear(); // Need to rename this method
      //store.action('alert/set', { message: 'Incorrect username or password.', type: 'error' });
    },
    register({ commit, state }, data) { },
    /*logout({ commit, state }) {
      store.mutate('session/clear'); // Need to rename this method
    }*/
  }
};
