<template>
  <popup ref="popup" exitable persistent max-width="600px" v-on="$listeners" @on-submit="submit" @on-clear="clear">
    <!-- Prompt Confirm -->
    <popup-prompt ref="prompt" />

    <!-- -->
    <v-form id="dialog-form" ref="form" :class="{ mobile: $vuetify.breakpoint.xs }" class="px-0 mx-0" @submit.prevent="">
      <v-row>
        <v-col class="column" cols="12" sm="6" md="6">
          <field-name :value.sync="fields.account_id" :disabled="!$store.session.isMod" required />
        </v-col>

        <!-- Date Picker -->
        <v-col class="column" cols="12" sm="6" md="6">
          <!-- {{ fields.timestamp }} {{ fields.timestamp.length }} -->
          <datetime-picker label="Landed" hint="" persistent-hint v-model="fields.timestamp" mysql />
          <!--
          <v-dialog ref="dialog" v-model="models.dateShow" :return-value.sync="fields.timestamp" persistent width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="models.date"
                label="Date"
                readonly
                v-bind="attrs"
                tabindex="-1"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="models.date" scrollable :max="new Date().toISOString()" color="primary">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="models.dateShow = false">Cancel</v-btn>
              <v-btn text color="primary" @click="$refs.dialog.save(models.date)">OK</v-btn>
            </v-date-picker>
          </v-dialog>
          -->
        </v-col>

        <!--<v-col v-show="duration != 0" class="column" cols="12" sm="6" md="4">
            <v-text-field label="Duration" :value="duration + ' hrs'" disabled></v-text-field>
          </v-col>-->

        <v-col class="column" cols="12" sm="6" md="6">
          <v-text-field
            v-model.number="fields.tach_start"
            label="Start of flight"
            hint="Copied from last log"
            persistent-hint
            type="number"
            color="red"
            step="0.1"
            required
            :disabled="!$store.session.isMod"
          ></v-text-field>
        </v-col>

        <v-col class="column" cols="12" sm="6" md="6">
          <v-text-field
            v-model.number="fields.tach_end"
            label="Return to hangar"
            :hint="duration | formatNumber(' hrs')"
            persistent-hint
            type="number"
            step="0.1"
            :min="fields.tach_start"
            :rules="tachEndRules"
            required
          ></v-text-field>
        </v-col>

        <v-col class="column" cols="12" sm="6" md="6">
          <v-text-field
            v-model="fields.oil"
            label="Oil (qts)"
            type="number"
            step="0.01"
            min="0"
            @focus="$event.target.select()"
            @change="onOilChange"
          ></v-text-field>
        </v-col>

        <v-col v-if="!isFuelFull" class="column" md="3" sm="3">
          <v-text-field
            v-model.number="fields.fuel_down"
            label="Fuel Down (hrs)"
            type="number"
            step="0.1"
            min="0"
            :rules="isFuelFull ? [] : fuelDownRules"
            :disabled="isFuelFull"
          ></v-text-field>
        </v-col>

        <v-col :sm="isFuelFull ? 0 : 3" class="mt-3 column" style="height: 58px">
          <v-btn
            :color="isFuelFull ? 'green' : 'grey'"
            :dark="isFuelFull"
            elevation="0"
            :outlined="!isFuelFull"
            block
            @click="fields.fuel_down = isFuelFull ? 0 : -0.1"
          >
            Full
          </v-btn>
        </v-col>

        <v-col class="column" cols="12">
          <v-text-field v-model="fields.remarks" label="Remarks" autocomplete="one-time-code"></v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <!-- -->
  </popup>
</template>

<script>
//import DatetimePicker from '@/components/user/datetime-picker';

export default {
  components: {
    //DatetimePicker: () => import('@/components/user/datetime-picker'),
    //DatetimePicker,
  },
  data() {
    return {
      fields: {
        account_id: 0,
        aircraft_id: '',
        changed_at: null,
        created_at: null,
        fuel_down: 0,
        is_removed: 0,
        remarks: '',
        oil: 0,
        tach_end: 0,
        tach_start: 0,
        timestamp: new Date(),
      },
      models: {},
      edit: null,
      aircraft_id: null,
      tachEndRules: [
        // v => v < this.fields.tach_start + 100 || '100+ hours!?',
        (v) => v > this.fields.tach_start || 'Return time must be greater',
      ],
      fuelDownRules: [(v) => v !== 0 || 'Please specify hours'],
    };
  },
  computed: {
    isEditing() {
      return !!this.fields.id;
    },
    isFuelFull() {
      return this.fields.fuel_down < 0;
    },
    duration() {
      return parseFloat((this.fields.tach_end - this.fields.tach_start).toFixed(1));
    },
  },
  methods: {
    open(title, edit, aircraft_id) {
      const promise = this.$refs.popup.open({ title }, edit, aircraft_id);
      this.edit = edit;
      this.aircraft_id = aircraft_id;
      this.clear();
      return promise;
    },
    close() {
      this.$refs.popup.close(null);
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.$refs.popup.close({ ...this.fields });
      }
    },
    async clear() {
      this.fields = this.$options.data().fields; // Assign defaults

      if (this.edit) {
        Object.assign(this.fields, this.edit); // Override with edited object fields
      } else {
        this.fields.aircraft_id = this.aircraft_id;
        this.fields.account_id = this.$store.session.data.account_id;

        const entryLatest = await this.$api.tachlog.select_latest({ aircraft_id: this.aircraft_id });
        if (entryLatest) {
          this.fields.tach_start = entryLatest.tach_end;
          this.fields.tach_end = this.fields.tach_start;
        }
      }
    },
    onOilChange() {
      console.log(Date.now());
    },
  },
};
</script>

<style scoped>
.border2 {
  border: 1px solid red;
}

.mobile .column {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
