export default function (previous) {
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var elapsed = Date.now() - previous.getTime();

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + ' seconds ago';
  } else if (elapsed < msPerHour) {
    const value = Math.round(elapsed / msPerMinute);
    return `${value} minute${value > 1 ? 's' : ''} ago`;
  } else if (elapsed < msPerDay) {
    const value = Math.round(elapsed / msPerHour);
    return `${value} hour${value > 1 ? 's' : ''} ago`;
  } else if (elapsed < msPerMonth) {
    const value = Math.round(elapsed / msPerDay);
    return `${value} day${value > 1 ? 's' : ''} ago`;
  } else if (elapsed < msPerYear) {
    const value = Math.round(elapsed / msPerMonth);
    return `${value} month${value > 1 ? 's' : ''} ago`;
  } else {
    const value = Math.round(elapsed / msPerYear);
    return `${value} year${value > 1 ? 's' : ''} ago`;
  }
}
