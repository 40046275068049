<template>
  <popup ref="popup" exitable persistent max-width="600px" v-on="$listeners" @on-submit="submit" @on-clear="clear">
    <!-- Prompt Confirm -->
    <popup-prompt ref="prompt" />

    <template v-slot:append>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-text-field v-model="fields.name_first" label="First Name" tabindex="-1" autocomplete="one-time-code" />
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field v-model="fields.name_last" label="Last Name" autocomplete="one-time-code" />
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field v-model="fields.username" label="Email" autocomplete="one-time-code" />
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field v-model="fields.mobile" label="Mobile" autocomplete="one-time-code" />
        </v-col>

        <v-col cols="12" class="mb-0">
          <v-select v-model="fields.certificates" :items="selectCertificates" label="Certificates" multiple />
        </v-col>

        <!--<v-col cols="12" sm="6" md="6">
          <v-select v-model="fields.groups" :items="selectGroups" label="Groups" multiple />
        </v-col>-->
      </v-row>
    </template>

    <!-- -->
    <v-form id="dialog-form" ref="form" :class="{ mobile: $vuetify.breakpoint.xs }" class="px-0 mx-0" @submit.prevent="">
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-select v-model="fields.type_id" :items="selectTypes" label="Type"></v-select>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="fields.password"
            label="Password"
            :type="showPassword ? 'text' : 'password'"
            hint="Leave blank to keep password"
            persistent-hint
            :counter="0"
            :rules="passwordRules"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <!-- -->
  </popup>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      certificatesRaw: [],
      showPassword: false,
      selectGroups: ['None'],
      selectTypes: [
        { text: 'Regular', value: 1 },
        { text: 'Moderator', value: 2 },
        { text: 'Admin', value: 3 },
      ],
      fields: {
        name_first: '',
        name_last: '',
        username: '',
        mobile: '',
        type_id: 1,
        groups: [],
        certificates: [],
      },
      passwordRules: [
        (v) => (v && v.length > 120 ? 'Password must be less than 120 characters' : true),
        (v) => (v && v.length < 5 ? 'Password must be 5 or more characters' : true),
      ],
      edit: null,
    };
  },
  computed: {
    isEditing() {
      return !!this.fields.id;
    },
    selectCertificates() {
      return this.certificatesRaw.map((certificate) => {
        return {
          text: certificate.label,
          value: certificate.id,
        };
      });
    },
  },
  methods: {
    open(title, edit) {
      const promise = this.$refs.popup.open({ title }, edit);
      this.edit = edit;
      this.clear();
      return promise;
    },
    close() {
      this.$refs.popup.close(null);
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.$refs.popup.close(this.fields);
      }
    },
    clear() {
      this.fields = this.$options.data().fields; // Assign defaults

      if (this.edit) {
        Object.assign(this.fields, this.edit); // Override with edited object fields

        // Override
        this.fields.groups = JSON.parse(this.fields.groups);
        this.fields.certificates = JSON.parse(this.fields.certificates);
      }
    },
  },
  async mounted() {
    const query = await this.$api.certificate.select_all_minimal();
    this.certificatesRaw = query.results;
  },
};
</script>

<style scoped>
.border2 {
  border: 1px solid red;
}
</style>
