<template>
  <!--<v-snackbar v-model="snackbar" top color="transparent" elevation="0" :width="width" class="pa-0 ma-0">
 :transition="transition" 

 success, info, warning, error
  </v-snackbar>-->
  <v-expand-transition>
    <v-alert
      v-show="$store.alert.state.message.length"
      border="left"
      :type="$store.alert.state.type"
      :value="true"
      :dismissible="$store.alert.state.dismissible"
      :elevation="elevation"
    >
      <div style="white-space: pre" v-html="$store.alert.state.message" />
    </v-alert>
  </v-expand-transition>
</template>

<script>
export default {
  props: {
    transition: {
      type: String,
      default: 'scale-transition',
    },
    width: {
      type: String,
      default: 'auto',
    },
    elevation: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      visible: true,
      snackbar: true,
    };
  },
  created() {
    /* setInterval(() => {
      this.visible = !this.visible;
    }, 1500); */
  },
  methods: {
    open(type, message, timeout) {
      this.$store.alert.set({ type, message, timeout });

      const promise = new Promise((resolve, reject) => {
        if (timeout) {
          setTimeout(() => resolve(), timeout);
        } else {
          resolve();
        }
      });

      return promise;
    },
    error(messageException, timeout) {
      /*let message = messageException;

      if (messageException instanceof Error) {
        message = messageException.stack || messageException.message;

        message = message.replaceAll('webpack-internal:///', '').replace('Error', '<b>Error</b>');
      }*/

      return this.open('error', messageException, timeout !== undefined ? timeout : 0);
    },
  },
};
</script>

<style scoped></style>
