// Imported from './src/main.js'

//import './base-components';

// Import auto-importer
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
const requireComponent = require.context('@/components/base', true, /\.vue$/);

// Import plugins
import pVuePerfectScrollbar from 'vue2-perfect-scrollbar';

// Import Styles
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import 'vue-navigation-bar/dist/vue-navigation-bar.css';
import '@/assets/scss/design-system/design-system.scss';

// Import components
import cVueNavigationBar from 'vue-navigation-bar';

export default {
  async install(Vue) {
    // Auto-Importer
    for (const file of requireComponent.keys()) {
      const componentConfig = requireComponent(file);
      let name = file
        .replace(/index.js/, '')
        .replace(/^\.\//, '')
        .replace(/\.\w+$/, '');
      // const componentName = upperFirst(camelCase(name));

      // Get name after last slash
      name = name.split('/');
      name = name[name.length - 1];

      Vue.component(name, componentConfig.default || componentConfig);
      //Vue.component(`Base${componentName}`, componentConfig.default || componentConfig);
    }

    // Plugins
    Vue.use(pVuePerfectScrollbar);

    // Components
    Vue.component('vue-navigation-bar', cVueNavigationBar);
    Vue.component('PrimaryLayout', () => import('../components/layout/index'));
  }
};
