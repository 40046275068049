import axios from 'axios';

export default {
  async insert(data) {
    return await axios.post('certificate_insert', { data });
  },
  async update(data) {
    return await axios.post('certificate_update', { data });
  },
  async select_all() {
    return (await axios.post('certificate_select_all')).data;
  },
  async select_all_minimal() {
    return (await axios.post('certificate_select_all_minimal')).data;
  },
};
