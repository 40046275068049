<template>
  <!-- <v-skeleton-loader height="100%" width="100%" type="card" :loading="$store.state.isLoading">  </v-skeleton-loader>-->
  <v-card min-width="200px" max-width="850px" class="mx-auto" :class="!$vuetify.breakpoint.smAndDown ? 'mb-6' : 'mb-4'">
    <!-- Dialog 
        <v-dialog v-model="dialog" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }"></template>

          <dialog-edit :aircraft-id="1" :account-id="1" @on-submit="handleSubmit($event)" @on-close="dialogHide($event)" />
        </v-dialog>-->

    <!--
    <v-card-title v-if="isOrigin" class="blue-grey lighten white--text font-weight-thin pb-3 pt-3 pr-3">
      There are no {{ hasEntries ? 'more' : '' }} entries
    </v-card-title>
     -->

    <v-card-title
      class="white--text font-weight-thin pb-3 pt-3 pr-3"
      :style="{ backgroundColor: color, borderColor: color }"
    >
      <span class="text-center" style="min-width: 48px">#{{ entry.id }}</span>

      <v-divider class="mx-4" vertical dark />

      <!-- Title -->
      <span>{{ date }}</span>

      <!--
      <template v-if="$vuetify.breakpoint.smAndUp">
        <v-divider class="mx-4" vertical dark />
        <span>{{ time }}</span>
      </template>

      <template v-else>
        <span>Tach Origin: {{ this.entry.tach_end | formatNumber }}</span>
      </template>
-->

      <v-spacer />

      <!-- Flag
        <v-btn v-show="entry.flagged" icon :dark="$vuetify.theme.dark || true" @click.prevent="flagToggle(entry)">
      </v-btn>-->

      <v-tooltip color="white" left>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-show="flagged" v-bind="attrs" color="white" v-on="on">
            {{ flagIcon }}
          </v-icon>
        </template>
        <div class="black--text text-center">Alerted Admin</div>
      </v-tooltip>

      <!-- Dots -->
      <v-menu bottom left v-if="isEditable">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon dark v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <!-- Edit -->
          <v-list-item link @click.prevent="$emit('edit', entry)">
            <v-icon class="pr-4">mdi-pencil-outline</v-icon>
            <v-list-item-title>Edit</v-list-item-title>
          </v-list-item>

          <!-- Flag -->
          <v-list-item link @click.prevent="$emit('toggle-flag', entry)">
            <v-icon class="pr-4" :color="entry.flagged ? 'red' : 'inherit'">{{ flagIcon }}</v-icon>
            <v-list-item-title :class="{ 'red--text': entry.flagged }">
              {{ entry.flagged ? 'Un-Flag' : 'Flag' }}
            </v-list-item-title>
          </v-list-item>

          <!-- Remove -->
          <v-list-item link class="pr-6" @click.prevent="$emit('remove', entry)">
            <v-icon class="pr-4" color="red">mdi-trash-can-outline</v-icon>
            <v-list-item-title class="red--text">Remove</v-list-item-title>
          </v-list-item>

          <!-- Insert Before
          <v-list-item v-if="i > 0" link @click.prevent="$emit('on-insert-above', entry)">
            <v-icon class="pr-4">mdi-plus-thick</v-icon>
            <v-list-item-title> Insert Above </v-list-item-title>
          </v-list-item>

          <template>
            Move Up/Down
            <v-list-item link class="pr-6" @click.prevent="$emit('on-move-up', entry)">
              <v-icon class="pr-4">mdi-arrow-up-thick</v-icon>
              <v-list-item-title>Shift Up</v-list-item-title>
            </v-list-item>
            <v-list-item link class="pr-6" @click.prevent="$emit('on-move-down', entry)">
              <v-icon class="pr-4">mdi-arrow-down-thick</v-icon>
              <v-list-item-title>Shift Down</v-list-item-title>
            </v-list-item>
          </template>-->
        </v-list>
      </v-menu>
    </v-card-title>

    <template>
      <v-divider />

      <div class="pt-3 pb-0 px-4">
        <v-row>
          <v-col cols="10" sm="4" :class="fieldClass">
            <v-text-field label="Name" :value="name" />
          </v-col>

          <v-col cols="3" sm="2" :class="fieldClass">
            <v-text-field label="Tach Start" :value="entry.tach_start | formatNumber" />
          </v-col>

          <v-col cols="3" sm="2" :class="fieldClass" class="override">
            <v-text-field
              label="Tach End"
              :value="entry.tach_end | formatNumber"
              :hint="entry.tach_duration | formatNumber($vuetify.breakpoint.smAndDown ? null : ' hrs', 'Total: ')"
              persistent-hint
            />
          </v-col>

          <v-col sm="2" :class="fieldClass">
            <v-text-field label="Oil" :value="entry.oil + ' qts'" />
          </v-col>

          <v-col sm="2" :class="fieldClass">
            <v-btn v-if="isFuelFull" color="green" class="mt-3 px-0" dark block outlined text>
              <v-icon class="mr-1">mdi-gas-station</v-icon>Full
            </v-btn>
            <v-btn v-else color="error" text class="mt-3 px-0" dark block outlined depressed>
              <v-icon class="mr-1">mdi-gas-station</v-icon>{{ entry.fuel_down + ' hrs' }}
            </v-btn>
            <!--
              <v-text-field v-else label="Fuel Down" :value="entry.fuel_down + ' hrs'" />
              -->
          </v-col>
        </v-row>
      </div>

      <v-container v-if="entry.remarks.trim().length > 0" class="ma-0 pa-0">
        <v-divider />
        <v-card-text class="pt-5 pb-2">
          <v-text-field color="teal" label="Remarks" :value="entry.remarks" />
        </v-card-text>
      </v-container>
    </template>
  </v-card>
</template>

<script>
const nth = function (d) {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export default {
  props: {
    entry: {
      type: Object,
      required: true,
    },
    users: {
      type: Array,
      default: [],
    },
    entryLatest: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isEditable: this.entry.id === this.entryLatest?.id || this.$store.session.isMod,
    };
  },
  computed: {
    color() {
      return this.flagged ? '#ec1313' : '#607d8b';
    },
    isFuelFull() {
      return this.entry.fuel_down < 0;
    },
    flagged() {
      return this.entry.flagged;
    },
    flagIcon() {
      return this.flagged ? 'mdi-flag-variant' : 'mdi-flag-variant-outline';
    },
    fieldClass() {
      return this.$vuetify.breakpoint.smAndDown ? 'py-0 my-0' : '';
    },
    date() {
      const date = new Date(this.entry.timestamp.replace(/-/g, '/'));
      const dateSplit = date
        .toLocaleString('en-US', {
          weekday: this.$vuetify.breakpoint.smAndDown ? 'short' : 'long',
          day: 'numeric',
          month: 'long',
        })
        .split(' ');

      /*console.log('-');
      console.log(this.entry.timestamp);
      console.log(date);*/
      return `${dateSplit[0]} ${dateSplit[1]} ${dateSplit[2]}${nth(dateSplit[2])}`;
    },
    time() {
      return '';
      const date = new Date(this.entry.timestamp);
      return date.toLocaleString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' });
    },
    name() {
      const user = this.users.find((user) => {
        return user.id == this.entry.account_id;
      });

      return user?.name || 'Unknown';
    },
  },
};
</script>

<style scoped>
.disable-events,
.v-text-field {
  pointer-events: none;
}

/*.theme--light .override .v-messages__message {
  color: black;
}*/
</style>
