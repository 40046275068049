// Imported from './src/main.js'

import mixinDark from './dark';
import mixinLandscape from './landscape';
import mixinIsAtTop from './isAtTop';

export default [
  mixinDark,
  mixinLandscape,
  mixinIsAtTop
];
