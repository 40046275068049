
<template>
  <v-text-field v-model="data" hide-details autocomplete="one-time-code" :disabled="disabled">
    <template v-slot:append>
      <v-menu v-model="isOpen" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
        <template v-slot:activator="{ on }">
          <div :style="swatchStyle" v-on="on" />
        </template>
        <v-card>
          <v-card-text class="pa-0">
            <v-color-picker v-model="data" :mode="mode" flat />
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'rgba',
    },
    noalpha: {
      type: Boolean,
      default: false,
    },
    null: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    data: {
      get() {
        if (this.null.length && this.value.startsWith(this.null)) {
          return 'NULL';
        }

        // temporary fix while there is no way to disable the alpha channel in the colorpicker component: https://github.com/vuetifyjs/vuetify/issues/9590
        if (this.noalpha && this.value.match(/#[a-zA-Z0-9]{8}/)) {
          return this.value.substr(0, 7);
        }

        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    swatchStyle() {
      const { data, isOpen, disabled } = this;
      const color = !disabled ? data : '#E2E2E2';

      return {
        backgroundColor: color,
        cursor: 'pointer',
        height: '25px',
        width: '25px',
        borderRadius: '100%',
        transition: 'border-radius 200ms ease-in-out',
        border: `1px solid ${this.$vuetify.theme.dark ? '#FFFFFF' : '#9B9B9B'}`,
      };
    },
  },
  methods: {},
};
</script>

<style scoped></style>
