import store from '@/store';

export default {
  created() {
    this.$vuetify.theme.setDark = function (value) {
      this.dark = value;

      store.action('session/set', { isDark: value });
      store.action('session/save');
      // store.dispatch('session/set', {isDark: value});
      // store.dispatch('session/save');
    };

    this.$vuetify.theme.toggleDark = function () {
      this.setDark(!this.dark);
    };

    // Load from session
    const data = store.get('session/data');
    this.$vuetify.theme.setDark(!!data.isDark);
    // this.$vuetify.theme.dark = !!data.isDark;
  }
};
