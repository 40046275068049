import axios from 'axios';

export default {
  async insert(data) {
    const output = (await axios.post('account_insert', { data })).data;
    if (output.error) {
      throw new Error(output.error);
    }

    return output.result;
  },
  async update(data) {
    const output = (await axios.post('account_update', { data })).data;
    if (output.error) {
      throw new Error(output.error);
    }

    return output.result;
  },
  async select_all() {
    return (await axios.post('account_select_all')).data;
  },
  async select_all_minimal() {
    const results = (await axios.post('account_select_all_minimal')).data.results;
    return results.map((user) => {
      return {
        id: user[0],
        name_first: user[1],
        name_last: user[2],
        name: user[1] + ' ' + user[2],
      };
    });
  },
  async select_all_support() {
    return (await axios.post('account_select_all_support')).data.results;
  },
  async is_admin(data) {
    return (await axios.post('account_is_admin', { data })).data.result;
  },
  async login(data) {
    const output = (await axios.post('account_login', { data })).data;
    if (output.error) {
      throw new Error(output.error);
    }

    return output.result;
  },
  async set_enabled(data) {
    return (await axios.post('account_set_enabled', { data })).data.result;
  },
  async set_password_all(data) {
    const output = (await axios.post('account_set_password_all', { data })).data;

    if (output.error) {
      throw new Error(output.error);
    }

    return output.result;
  }
};
