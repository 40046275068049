<template>
  <v-avatar :color="color" :size="size">
    <span class="white--text">{{ text }}</span>
  </v-avatar>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: '32',
    },
    text: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '#ff5722',
    },
  },
};
</script>

<style scoped>
</style>
