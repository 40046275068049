<template>
  <v-dialog v-model="display" :width="width">
    <template v-slot:activator="{ on }">
      <v-text-field
        v-bind="textFieldProps"
        :disabled="disabled"
        :loading="loading"
        :label="label"
        :value="formattedDatetime"
        v-on="on"
        readonly
        :hint="hint"
        :persistent-hint="persistentHint"
      >
        <template v-slot:progress>
          <slot name="progress">
            <v-progress-linear color="primary" indeterminate absolute height="2"></v-progress-linear>
          </slot>
        </template>
      </v-text-field>
    </template>

    <v-card>
      <v-card-text class="px-0 py-0">
        <v-tabs fixed-tabs hide-slider v-model="activeTab">
          <v-tab key="calendar">
            <slot name="dateIcon">
              <v-icon>mdi-calendar-month</v-icon>
            </slot>
          </v-tab>

          <v-tab key="timer" :disabled="dateSelected">
            <slot name="timeIcon">
              <v-icon>mdi-clock-time-four-outline</v-icon>
            </slot>
          </v-tab>

          <v-tab-item key="calendar">
            <v-date-picker
              v-model="date"
              v-bind="datePickerProps"
              @input="showTimePicker"
              full-width
              :max="new Date().toISOString()"
            />
          </v-tab-item>

          <v-tab-item key="timer">
            <v-time-picker
              ref="timer"
              class="v-time-picker-custom"
              v-model="time"
              v-bind="timePickerProps"
              full-width
              ampm-in-title
              format="ampm"
              scrollable
            ></v-time-picker>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="actions" :parent="this">
          <v-btn color="grey lighten-1" text @click.native="clearHandler">{{ clearText }}</v-btn>
          <v-btn color="green darken-1" text @click="okHandler">{{ okText }}</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const DEFAULT_DATE = '';
const DEFAULT_TIME = '00:00:00';
const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
const DEFAULT_TIME_FORMAT = 'HH:mm:ss';
const DEFAULT_DIALOG_WIDTH = 340;
const DEFAULT_CLEAR_TEXT = 'CLEAR';
const DEFAULT_OK_TEXT = 'OK';

export default {
  name: 'v-datetime-picker',
  model: {
    prop: 'datetime',
    event: 'input',
  },
  props: {
    datetime: {
      type: [Date, String],
      default: null,
    },
    disabled: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    label: {
      type: String,
      default: '',
    },
    dialogWidth: {
      type: Number,
      default: DEFAULT_DIALOG_WIDTH,
    },
    dateFormat: {
      type: String,
      default: DEFAULT_DATE_FORMAT,
    },
    timeFormat: {
      type: String,
      default: 'HH:mm',
    },
    clearText: {
      type: String,
      default: DEFAULT_CLEAR_TEXT,
    },
    okText: {
      type: String,
      default: DEFAULT_OK_TEXT,
    },
    textFieldProps: {
      type: Object,
    },
    datePickerProps: {
      type: Object,
    },
    timePickerProps: {
      type: Object,
    },
    hint: {
      type: String,
      default: '',
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    mysql: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      display: false,
      activeTab: 0,
      date: DEFAULT_DATE,
      time: DEFAULT_TIME,
    };
  },
  mounted() {
    this.init();
    this.$emit('input', this.selectedDatetime);
  },
  computed: {
    width() {
      return this.dialogWidth; //this.activeTab == 0 ? this.dialogWidth : 500;
    },
    dateTimeFormat() {
      return this.dateFormat + ' ' + this.timeFormat;
    },
    formattedDatetime() {
      const date = date instanceof Date ? this.selectedDatetime : new Date(this.selectedDatetime.replace(/-/g, '/'));

      if (date) {
        const dateStr = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        const timeStr = date.toLocaleString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' });

        return `${dateStr}  ${timeStr}`;
      }

      return ''; //this.selectedDatetime ? format(this.selectedDatetime, this.dateTimeFormat) :
    },
    selectedDatetime() {
      if (this.date && this.time) {
        let datetimeString = this.date + ' ' + this.time;
        if (this.time.length === 5) {
          datetimeString += ':00';
        }

        //console.log(datetimeString, DEFAULT_DATE_FORMAT + ' ' + DEFAULT_TIME_FORMAT);
        /*console.log(
          3,
          this.mysql ? new Date(datetimeString).toISOString().slice(0, 19).replace('T', ' ') : new Date(datetimeString)
        );*/

        return this.mysql ? datetimeString : new Date(datetimeString); //parse(datetimeString, DEFAULT_DATE_FORMAT + ' ' + DEFAULT_TIME_FORMAT, new Date());
      } else {
        return null;
      }
    },
    dateSelected() {
      return !this.date;
    },
  },
  methods: {
    init() {
      if (!this.datetime) {
        return;
      }

      let date;
      if (this.datetime instanceof Date) {
        date = this.datetime;
      } else if (typeof this.datetime === 'string' || this.datetime instanceof String) {
        // see https://stackoverflow.com/a/9436948
        date = new Date(this.datetime.replace(/-/g, '/')); //parse(this.datetime, this.dateTimeFormat, new Date());
      }

      const month = ('0' + (date.getMonth() + 1)).slice(-2); // two digits
      const day = ('0' + date.getDate()).slice(-2); // two digits
      this.date = `${date.getFullYear()}-${month}-${day}`; //yyyy-mm-dd //format(date, DEFAULT_DATE_FORMAT);
      this.time = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`; //hh:mm:ss format(date, DEFAULT_TIME_FORMAT);

      /*const datetime = date.slice(0, 19).toISOString().split('T');
      this.date = datetime[0];
      this.time = datetime[1];*/
    },
    okHandler() {
      this.resetPicker();
      this.$emit('input', this.selectedDatetime);
    },
    clearHandler() {
      this.resetPicker();
      this.date = DEFAULT_DATE;
      this.time = DEFAULT_TIME;
      this.$emit('input', null);
    },
    resetPicker() {
      this.display = false;
      this.activeTab = 0;
      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true;
      }
    },
    showTimePicker() {
      this.activeTab = 1;
    },
  },
  watch: {
    datetime: {
      handler() {
        this.init();
        this.$emit('input', this.selectedDatetime);
      },
      immediate: true,
    },
  },
};
</script>
