<template>
  <popup
    ref="popup"
    v-bind="$props"
    max-width="400px"
    :padding="options.padding"
    :padding-title="options.paddingTitle"
    @keydown.enter="submit(options.returnSubmit)"
  >
    <!-- Default -->
    <div class="text-body-1 py-4">
      {{ message }}
    </div>

    <!-- Footer -->
    <template v-slot:footer>
      <v-col
        v-for="(entry, label, i) of options.entries"
        :key="i"
        :class="
          i < Object.keys(options.entries).length - 1 ? `mr-${Math.ceil(options.padding / options.buttonPaddingRatio)}` : ''
        "
      >
        <v-btn
          :color="entry.color"
          :dark="entry.dark"
          :outlined="entry.outlined"
          tabindex="0   "
          elevation="0"
          block
          @click.native="submit(entry.return)"
          >{{ label }}</v-btn
        >
      </v-col>
    </template>
  </popup>
</template>

<script>
export default {
  data() {
    return {
      message: '',
      options: {
        padding: 6,
        paddingTitle: 3,
        buttonPaddingRatio: 1,
        returnSubmit: true,
        entries: {
          Cancel: {
            return: false,
            outlined: false,
            color: 'primary',
            dark: false,
          },
          Confirm: {
            return: true,
            outlined: true,
            color: 'red',
            dark: true,
          },
        },
      },
    };
  },
  methods: {
    open(title, message, options) {
      this.message = message;
      this.options = Object.assign(this.options, options);

      return this.$refs.popup.open({ title });
    },
    close() {
      this.$refs.popup.close();
    },
    submit(result) {
      this.$refs.popup.close(result);
    },
  },
};
</script>
