<template>
  <div>
    <v-autocomplete
      v-bind="$attrs"
      v-model="temp"
      :input="value"
      :items="users"
      :filter="customFilter"
      item-text="name"
      item-value="id"
      label="Name"
      :rules="[...rules, ...nameRules]"
      autocomplete="one-time-code"
      @change="$emit('update:value', $event)"
    />
  </div>
</template>

<script>
export default {
  props: {
    rules: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Number,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      usersRaw: [],
      nameRules: [(v) => (v && v.length >= 1 ? 'Missing name' : true)],
      temp: this.value,
    };
  },
  computed: {
    label() {
      const match = this.users.find((user) => user.id == this.value);

      return match?.name || 'Name';
    },
    users() {
      return this.usersRaw;
    },
  },
  async mounted() {
    this.usersRaw = await this.$api.account.select_all_minimal(); //[...(await this.$api.account.select_all_minimal()), { id: 0, name_last: '', name_first: '', name: '' }];

    if (!this.required) {
      this.usersRaw = [{ id: null, name: 'None', name_first: '', name_last: '' }, ...this.usersRaw];
    }
  },
  methods: {
    customFilter(item, queryText, itemText) {
      if (!queryText) return true;

      const textOne = item.name_last.toLowerCase();
      const textTwo = item.name_first.toLowerCase();
      const text = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();
      const searchTexts = searchText.split(' ');
      const texts = text.split(' ');

      // If each field starts with
      let count = 0;
      for (const i in searchTexts) count += texts[i]?.startsWith(searchTexts[i]) || 0;
      if (count === searchTexts.length) return true;

      return textOne.startsWith(searchText) || textTwo.startsWith(searchText);
      // return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
    },
  },
};
</script>

<style scoped></style>
